import * as React from "react";
import { graphql } from "gatsby";
// import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { DataProvider } from "../../contentful/data-provider";
import PressSection from "../../components/sections/press-section";
import Seo from "../../components/seo";

const PressPage = ({ data }) => {
	const dataProvider = new DataProvider(data);
	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`GlassView | ${dataProvider.getLabel("press")}`} />
			<main className='pt-24 lg:pt-24'>
				<PressSection content={data.allContentfulPressPost.edges} dataProvider={dataProvider} />
			</main>
		</Layout>
	);
};

export default PressPage;

export const query = graphql`
	query ($locale: String!) {
		allContentfulPressPost(sort: { fields: publishedAt, order: DESC }, filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					id
					node_locale
					title
					slug
					publishedAt(formatString: "MMMM DD, YYYY", locale: $locale)
					body {
						raw
					}
					excerpt {
						raw
					}
				}
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
		allContentfulPage(filter: { contentful_id: { eq: "5vwqRx1UAitpB1zFrvblUv" }, node_locale: { eq: $locale } }) {
			edges {
				node {
					...CurrentPageDetails
				}
			}
		}
	}
`;
