import React from 'react';
import { Link } from 'gatsby';
import TitleSection from "./title-section";
import BtnArrow from "../elements/btn-arrow";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import moment from "moment";
import {getLocaleUrl} from "../../contentful/data-provider";

function PressSection(props) {
    const formatDate = dateString => moment(dateString).format('MMMM D, Y');
    return (
        <>
            <TitleSection content={{title: props.dataProvider.getLabel('press'), id: '0'}}/>
            <section className="pt-0 pb-[50px] md:pb-[100px]" > 
                <div className={'container'}>
                    <div className="press-section grid overflow-hidden grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 auto-rows-auto gap-[70px]">
                        {props.content.map((element, index) =>
                            <div key={index} className="row-end-auto">
                                <span className="date">
                                    {element.node.publishedAt}
                                </span>
                                <h2 className="title mt-2.5">
                                    {element.node.title}
                                </h2>
                                <p className="mt-5 content">
                                    {renderRichText(element.node.excerpt)}
                                </p>
                                <Link to={getLocaleUrl(props.content.node_locale) + element.node.slug}>
                                    <BtnArrow class={'btn-yellow text-black mt-[30px]'} title={props.dataProvider.getLabel('read-more')} size={'btn-xxs'} rotate={`rotate-[-45deg]`}/>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default PressSection;
